.app {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  height: 100vh;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  background-color: #f8f9fd;
  background-image: url("https://scontent.fotp3-3.fna.fbcdn.net/v/t39.30808-6/291916578_488845376473820_5781791685202042763_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=n1Da1nH7LIAAX87QLKj&_nc_oc=AQm2Hr4QqmTEVmTsQkinsW7o9o8unKs5SBNq_RZCOsa-bMuqOenIFNV8yL8sKP8vP66SNAhTlqZzsYq2CsvUU2lu&_nc_ht=scontent.fotp3-3.fna&oh=00_AfAnnU-iBb8wmL2HF3sPXFoVISJaxVOrNXEIJ4A125wR8Q&oe=64058525");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}

input[type="text"],
input[type="password"] {
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.21);
}

input[type="submit"] {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: #e7e6e1;
  border-radius: 25px;
  border: 1px solid black;
  width: 140px;
  height: 40px;
}

input[type="submit"]:hover {
  background: #87a96b;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: white;
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  border: 2px solid black;
  padding: 20px;
  opacity: 0.9;
  height: 300px;
  width: 350px;
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}
