@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

.Title {
  font-family: "Bebas Neue";
  font-size: 3em;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: linear-gradient(#ffeba7, #ffeba7);
  background-size: 100% 5px;
  background-repeat: no-repeat;
  background-position: 100% 0%;
  color: white;
  transition: background-size 0.7s, background-position 0.5s ease-in-out;
  margin-top: 3rem;
}

.Title:hover {
  background-size: 100% 100%;
  background-position: 0% 100%;
  transition: background-size 0.7s, background-position 0.5s ease-in-out;
}

.secondTitle{
  margin-top: 30%;
}
