.gridContainer {
  margin: 5rem;
  margin-top: 8rem;
  margin-bottom: 0;
  /* background-color: #1f2029; */
}
#gridContainer {
  border: 8px solid #555555;
  margin-bottom: 0;
}
/* 
.dx-datagrid-header-panel {

  background-color: #1f2029;

  
}
.dx-toolbar-items-container {

  background-color: #1f2029;

}

.dx-datagrid-headers {

  background-color: #1f2029;

} */

/* .dx-scrollable-wrapper {

  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.7;
  color: #c4c3ca;
  background-color: #1f2029;
  overflow-x: hidden;
} */

.dx-button-content {
  border-radius: 4px;
  height: 44px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  padding: 0 30px;
  letter-spacing: 1px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  border: none;
  background-color: #ffeba7;
  color: #102770;
  box-shadow: 0 8px 24px 0 rgba(255, 235, 167, 0.2);
}
.dx-button-content:active,
.dx-button-content:focus {
  background-color: #102770;
  color: #ffeba7;
  box-shadow: 0 8px 24px 0 rgba(16, 39, 112, 0.2);
}
.dx-button-content:hover {
  background-color: #102770;
  color: #ffeba7;
  box-shadow: 0 8px 24px 0 rgba(16, 39, 112, 0.2);
}
