@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
html {
  margin: 1px 1px 1px 1px;
}
body {
  background-image: url("https://media.istockphoto.com/id/1251205256/ro/fotografie/fundal-negru-total-grunge-abstract-ciment-beton-h%C3%A2rtie-textura-night-pattern-suprafata.jpg?s=612x612&w=0&k=20&c=-RIo3YCTtB1vju1p67EbPUvVzdz7Ueh2KMYIpbDySlY=");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  height: 750px;
}

.tab-wrapper {
  background: #272727;
  font-family: "Montserrat", sans-serif;
}
.navMenu {
  background-color: #e7e6e1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #555555;
}

.btn {
  outline: none;
  padding: 10px 20px;
  background: lightblue;
  color: light;
  width: 100%;
  cursor: pointer;
  font-size: 1.3rem;
  transition: 200ms linear;
}
button.btn {
  background-color: #555555;
  color: white;
  border: 1px solid whitesmoke;
}

button.btn:hover {
  background-color: #555555;
  color: #ffeba7;
}

.ContainerTabPanel{
  margin: 2rem;
  margin-bottom: 0;
}
.addButtonContainer{
  text-align: right;
}